/*
 * The NgModule for the training-tasks component.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingTasksComponent } from './training-tasks.component';
import { TrainingTasksRoutingModule } from './training-tasks-routing.module';
import { TasklistsComponent } from './tasklists/tasklists.component';
import { NbCardModule, NbTabsetModule, NbListModule, NbDialogModule, NbButtonModule } from '@nebular/theme';

import { TaskDetailComponent } from './tasklists/task-detail/task-detail.component';

@NgModule({
  declarations: [
    TrainingTasksComponent, 
    TasklistsComponent, 

    TaskDetailComponent],
  imports: [
    CommonModule,
    TrainingTasksRoutingModule,
    NbCardModule,
    NbTabsetModule,
    NbListModule,
    NbButtonModule,
    NbDialogModule.forChild(),
 
  ],
  entryComponents: [
    TaskDetailComponent
  ]
})
export class TrainingTasksModule { }
