/*
 * The tasklists component that lists user's ongoing tasks and completed tasks.
 */

import { Component, OnInit } from '@angular/core';
import { TrainingTaskService } from '../../../services/training-task.service';
//import { TrainingTask } from 'src/app/classes/training-task';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { NbDialogService } from '@nebular/theme';

import { Params, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tasklists',
  templateUrl: './tasklists.component.html',
  styleUrls: ['./tasklists.component.scss']
})
export class TasklistsComponent implements OnInit {

  constructor(
    private trainingTaskService:TrainingTaskService,
    private dialogService: NbDialogService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  authCredentials;
  
  trainingTasks: any[]; //TrainingTask[];
  ongoingTrainingTasks: any[]; //TrainingTask[];
  completedTrainingTasks: any[]; //TrainingTask[];

  ngOnInit() {
    /*this.trainingTaskService.getTasks()
    .subscribe(trainingTasks=>{
      this.trainingTasks = trainingTasks;
      this.ongoingTrainingTasks = trainingTasks;
      this.completedTrainingTasks = trainingTasks;
      console.log(trainingTasks);
    });*/

    this.authCredentials = this.authService.getCredentials();
    if (this.authCredentials == null) return;

    if (this.authCredentials.userID != null) {

      const userID = this.authCredentials.userID;

      //this.trainingTaskService.getTasks(userID).subscribe(trainingTasks => { this.trainingTasks = trainingTasks; });
      this.trainingTaskService.getOngoingTasks(userID).subscribe(ongoingTasks => { this.ongoingTrainingTasks = ongoingTasks; });
      this.trainingTaskService.getCompletedTasks(userID).subscribe(completedTasks => { this.completedTrainingTasks = completedTasks; });
    }
  }

  seeTaskDetails(task: any) { //TrainingTask) {
    this.dialogService.open(TaskDetailComponent, {
      context: {
        task: task,
        showAssignedTime: true
      },
    });
  }

}
