/*
 * The module that configures the router used in the training-tasks component. 
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { TrainingTasksComponent } from './training-tasks.component';
import { TasklistsComponent } from './tasklists/tasklists.component';

const routes: Routes = [
  { 
    path: '', 
    component: TrainingTasksComponent,
    children: [
      {
        path: 'tasklists',
        component: TasklistsComponent
      },
      {
        path: '',
        redirectTo: 'tasklists',
        pathMatch: 'full',
      }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrainingTasksRoutingModule { }
