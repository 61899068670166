/*
 * The training-tasks component where user can view his assigned tasks and tasks progresses.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-tasks',
  templateUrl: './training-tasks.component.html',
  styleUrls: ['./training-tasks.component.scss']
})
export class TrainingTasksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
